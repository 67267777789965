import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faInstagram, faTripadvisor, faLinkedin, faCcVisa, faCcDiscover, faCcMastercard, faCcJcb } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope, faDirections, faCreditCard, faStar } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'gatsby';

import icon_1 from '../../images/payments/visa.png'
import icon_2 from '../../images/payments/master-card.png'
import icon_3 from '../../images/payments/maestro.png'
import icon_4 from '../../images/payments/jcb.png'
import icon_5 from '../../images/payments/discover.png'

// import tripadvisor_certificat from '../../images/tripadvisor-excellence.png'

import("./style.sass");


const Footer = () => (
  <footer className="site-footer">
      <div className="inner-content">
        <div className="row">
          <div className="col-lg-12 site-name">Riad Chebbi <span className="hotel-stars"><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /></span></div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-sm-6 footer-block">
                <div className="company-address">
                    Tanamoust<br />52202 Merzouga, Maroc<br />
                    <a href="https://www.google.com/maps/place/Riad+Chebbi/@31.0739506,-4.0140365,17z/data=!3m1!4b1!4m8!3m7!1s0xd973d39cb8b0cc5:0x2b18b95ebfc84eb4!5m2!4m1!1i2!8m2!3d31.073946!4d-4.0118478" className="get-direction-btn" target="_blank" rel="noopener noreferrer">
                      Get direction <FontAwesomeIcon icon={faDirections} />
                    </a>
                </div>
              </div>
              <div className="col-sm-6 footer-block">
                <div className="contact-info">
                  <a href="tel:212659757391"><FontAwesomeIcon icon={faPhone} /> +212 659 757 391</a><br />
                  <a href="tel:212535578333"><FontAwesomeIcon icon={faPhone} /> +212 535 578 333</a><br />
                  <a href="mailto:info@riadchebbi.com"><FontAwesomeIcon icon={faEnvelope} /> info@riadchebbi.com</a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 footer-block">
            <ul className="menu">
              <li><Link to="/desert-camp">Desert Camp</Link></li>
              <li><Link to="/luxury-rooms-merzouga">Rooms</Link></li>
              <li><Link to="/luxury-suites-merzouga">Suites</Link></li>
              <li><Link to="/activities">Activities</Link></li>
              <li><Link to="/food-drinks">Restaurant</Link></li>
              <li><Link to="/about-us">About us</Link></li>
              <li><Link to="/contact">Contact</Link></li>
              <li><Link to="/terms-and-conditions">Terms & Conditions</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
            </ul>
          </div>
          <div className="col-md-12">
            <div className="payment-block">
              <div className="payment-text">
              ✓ FREE cancellation <br /> ✓ NO PREPAYMENT NEEDED - pay at the property
              </div>

              <div className="payment-methods">
                <ul>
                  <li><FontAwesomeIcon icon={faCreditCard} /></li>  
                  <li><FontAwesomeIcon icon={faCcVisa} /></li>  
                  <li><FontAwesomeIcon icon={faCcDiscover} /></li>  
                  <li><FontAwesomeIcon icon={faCcMastercard} /></li>  
                  <li><FontAwesomeIcon icon={faCcJcb} /></li>  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="inner-content">
          <div className="float-left copyright-text">
              © 2019 Riad Chebbi. All rights reserved - Powered by <a href="https://khalidcreations.com/" target="_blank">Khalid Creations</a>
          </div>
          <div className="float-right">
            <div className="social-links">
              <ul>
                  <li className="facebook">
                    <a aria-label="Facebook" href="https://www.facebook.com/Riad-Chebbi-591686921331589/" target="_blank" rel="noopener noreferrer" title="Facebook">
                      <FontAwesomeIcon icon={faFacebookF} /><span>Facebook</span>
                    </a>
                  </li>		
                  <li className="linkedin">
                    <a aria-label="Twitter" href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer" title="Linkedin">
                      <FontAwesomeIcon icon={faLinkedin} /><span>Linkedin</span>
                    </a>
                  </li>	
                  <li className="instagram">
                    <a aria-label="Instagram" href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer" title="Instagram">
                      <FontAwesomeIcon icon={faInstagram} /><span>instagram</span>
                    </a>
                  </li>	
                  <li className="tripadvisor">
                    <a aria-label="tripadvisor" href="https://www.tripadvisor.com/Hotel_Review-g304017-d17847805-Reviews-Riad_Chebbi-Merzouga_Meknes_Tafilalet_Region.html" target="_blank" rel="noopener noreferrer" title="Youtube">
                      <FontAwesomeIcon icon={faTripadvisor} /><span>TripAdvisor</span>
                    </a>
                  </li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
)

export default Footer
import React from 'react'

import "./style.sass"

const PageBanner = ({image, title}) => (
  <div className="page-banner" style={{ backgroundImage: 'url("'+ image +'")' }}>
    <div className="inner-content">
      <h1>{title}</h1>
    </div>
  </div>
)

export default PageBanner
/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons'

import Header from "../Header"
import Footer from "../Footer"

import "bootstrap/scss/bootstrap.scss"
import "./style.sass"

const Layout = ({ children, sidebarComponent, className, sidebarTriggerTitle }) => {

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className={ "page-content" + (className ? (' '+className) : '') }> 
        <Header siteTitle={data.site.siteMetadata.title} sidebarTriggerTitle={sidebarTriggerTitle}/>

        {children}

        <Footer />
      </div>

      <div className="sidebar">
        <button
          className="close-btn"
          onClick={() => {
            document.body.classList.remove('show-sidebar');
          }}
        ><FontAwesomeIcon icon={faTimesCircle} /></button> 

        {sidebarComponent}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import PropTypes from "prop-types"
import React, { useState } from "react"
import { Link } from 'gatsby'
import Hamburger from "react-hamburgers"
import uuid from 'uuid'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faInstagram, faPinterest, faYoutube, faTripadvisor, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faPhone, faEnvelope, faCalendarAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons'

import logo from '../../images/logo.jpg' 
import small_logo from '../../images/small-logo.jpg' 

import("hamburgers/_sass/hamburgers/hamburgers.scss")
import("./style.sass")


const Header = ({ siteTitle, sidebarTriggerTitle}) => {

  const [menuActive, setMenuActive] = useState(false)

  const mainMenuItems =  [
    { url: "/", text: "Home" },
    { url: "/desert-camp", text: "Desert Camp" },
    { url: "/luxury-rooms-merzouga", text: "Rooms" },
    { url: "/luxury-suites-merzouga", text: "Suites" },
    { url: "/activities", text: "Activities" },
    { url: "/food-drinks", text: "Restaurant" },
    { url: "/about-us", text: "About us" },
    { url: "/contact", text: "Contact" }
  ];

  const secondaryMenuItems =  [
    //{ url: "/blog", text: "Blog" },
    { url: "/terms-and-conditions", text: "Terms & Conditions" },
    { url: "/privacy-policy", text: "Privacy Policy" }
  ];



  return (
    <header className="site-header">

      <Link to="/" className="site-logo">
        <img src={ logo } alt="" className="b-logo"/>
        <img src={ small_logo } alt="" className="s-logo"/>
      </Link>
      
      <div
        className="site-nav-trigger"
        onClick={() => {

          if(!document.body.classList.contains('show-nav'))
            document.body.classList.add('show-nav');
          else
            document.body.classList.remove('show-nav'); 
          
          setMenuActive(!menuActive);
          
        }}
      >
        <span className="text">Menu</span>
        <Hamburger active={menuActive} />
      </div> 

      <div
        className="sidebar-trigger"
        onClick={() => {
          if(!document.body.classList.contains('show-sidebar'))
              document.body.classList.add('show-sidebar');
            else
              document.body.classList.remove('show-sidebar');        
        }}
      >
        <FontAwesomeIcon icon={faCalendarAlt} /> {sidebarTriggerTitle && sidebarTriggerTitle}{!sidebarTriggerTitle && 'Book Now'} 
      </div>

      <a className="site-phone" href="tel:212659757391">
        <FontAwesomeIcon icon={faPhoneAlt} /> +212 6 59 75 73 91
      </a>

      <div className="site-nav">
        
        <div>
          <ul className="main-menu">
            {mainMenuItems.map(item => (
              <li key={uuid.v4()}>
                <Link
                  to={item.url}
                  onClick={() => {
                    document.body.classList.remove('show-nav');
                    setMenuActive(!menuActive);
                  }}
                >{item.text}</Link>
              </li>
            ))}
          </ul>  
          
          <hr />

          <ul className="secondary-menu">
            {secondaryMenuItems.map(item => (
              <li key={uuid.v4()}>
                <Link
                  to={item.url}
                  onClick={() => {
                    document.body.classList.remove('show-nav');
                    setMenuActive(!menuActive);
                  }}
                >{item.text}</Link>
              </li>
            ))}
          </ul> 

          <hr />

          <div className="site-nav-bottom">
            <div>
              <a href="https://www.google.com/maps/place/Riad+Chebbi/@31.0739506,-4.0140365,17z/data=!3m1!4b1!4m8!3m7!1s0xd973d39cb8b0cc5:0x2b18b95ebfc84eb4!5m2!4m1!1i2!8m2!3d31.073946!4d-4.0118478" target="_blank" rel="noopener noreferrer">
                Tanamoust<br />52202 Merzouga, Maroc
              </a>
            </div>
            <div>
                <a href="tel:212659757391" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faPhone} /> +212 659 757 391</a><br/>
                <a href="mailto:info@riadchebbi.com" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faEnvelope} /> info@riadchebbi.com</a>
            </div>
          </div>

          <ul className="social-links">
            <li><a href="https://www.facebook.com/Riad-Chebbi-591686921331589/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faFacebookF} /></a></li>
            <li><a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a></li>
            <li><a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a></li>
            <li><a href="https://www.tripadvisor.com/Hotel_Review-g304017-d17847805-Reviews-Riad_Chebbi-Merzouga_Meknes_Tafilalet_Region.html" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTripadvisor} /></a></li>
          </ul>

        </div>

      </div>

      <div
        className="site-nav-bg"
        onClick={() => {
          document.body.classList.remove('show-nav');
          setMenuActive(!menuActive);
        }}
      ></div> 

    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import uuid from 'uuid'
import axios from 'axios'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faCalendarCheck, faBed, faUserAlt, faListUl, faPhone, faEnvelope, faCommentAlt, faUsers, faCampground } from '@fortawesome/free-solid-svg-icons'
import { StaticQuery, graphql, Link } from 'gatsby';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

import('./style.sass')
const required_msg = ' - Required'

const bookinkSchema = Yup.object().shape({

  product: Yup.string()
    .required(required_msg),
    
  // product_name: Yup.string()
  //   .required(required_msg),

  guests: Yup.number()
    .min(1)
    .required(required_msg),

  arrival_date: Yup.date()
  .required(' - Required'),

  departure_date: Yup.date()
    .required(required_msg),

  full_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(required_msg),

  phone: Yup.string()
    .required(required_msg),

  email: Yup.string()
    .email('Invalid email')
    .required(required_msg),
})

const BookingForm = ({colWidth, productType, hideProductType, defaultProduct}) => {

  if(colWidth === undefined)
    colWidth = 'col-md-4'

  const [productTypeSelected, setProductTypeSelected] = useState(productType)
  const [productSelected, setProductSelected] = useState(defaultProduct)

  const date_now = new Date()
  const m = (date_now.getMonth()+1) < 10 ? ('0'+ (date_now.getMonth()+1)) : (date_now.getMonth()+1)
  const d = date_now.getDate() < 10 ? ('0'+ date_now.getDate()) : date_now.getDate()
  const min_date = date_now.getFullYear() + '-' + m + '-' + d

  /*
  var tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const tm = (tomorrow.getMonth()+1) < 10 ? ('0'+ (tomorrow.getMonth()+1)) : (tomorrow.getMonth()+1)
  const td = tomorrow.getDate() < 10 ? ('0'+ tomorrow.getDate()) : tomorrow.getDate()
  const t_min_date = tomorrow.getFullYear() + '-' + tm + '-' + td
  */

  return (
    <StaticQuery
      query={
        graphql`
          query allProducts {
            allMarkdownRemark (
              filter: {
                frontmatter: {
                  type: {
                    nin: ["slide", "Blog"]
                  }
                }
              }
              sort: { order: DESC, fields: [frontmatter___date]}
            ) {
              edges {
                node {
                  id
                  frontmatter {
                    title
                    type
                  }
                }
              }
            }
          }
        `
      }

      render={(data) => {

        let product_types = []
        let product_type_options = [] 

        data.allMarkdownRemark.edges.map(({node}) => {
          if(!product_types.includes(node.frontmatter.type)) {
            product_types.push(node.frontmatter.type)
            product_type_options.push(
              <option
                key={uuid.v4()}
                value={node.frontmatter.type}
              >{node.frontmatter.type}</option>
            )
          }

          return true

        })

        return (
          <div className="booking-form">
            <div className="booking-form-title">Booking</div>
            <Formik
              initialValues={{
                product: productType ? productType : '',
                product_name: defaultProduct ? defaultProduct : '',
                guests: '',
                arrival_date: '',
                departure_date: '',
                full_name: '',
                phone: '',
                email: '',
                message: '',
              }}
              validationSchema={bookinkSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                                
                var bodyFormData = new FormData()

                bodyFormData.set('product', values.product)
                bodyFormData.set('product_name', values.product_name)
                
                if(values.bed_type !== undefined)
                  bodyFormData.set('bed_type', values.bed_type)

                if(values.camp_type !== undefined)
                  bodyFormData.set('camp_type', values.camp_type)
                
                bodyFormData.set('guests', values.guests)
                bodyFormData.set('arrival_date', values.arrival_date)
                bodyFormData.set('departure_date', values.departure_date)

                bodyFormData.set('full_name', values.full_name)
                bodyFormData.set('phone', values.phone)
                bodyFormData.set('email', values.email)
                bodyFormData.set('message', values.message)
                bodyFormData.set('subject', 'Booking')

                axios.post('https://www.riadchebbi.com/contact.php', bodyFormData)
                  .then((res) => {
                    if(res.data.success) {
                      alert('The booking has been sent! We will contact as soon as possible.')
                      setSubmitting(false)
                      resetForm()
                    }
                  })
                  
              }}
            >
              {({ isSubmitting, setFieldValue }) => (
                <Form>

                  <div className="row">
                    
                    <div className={colWidth} style={{display: hideProductType ? 'none' : 'block'}}>
                      <div className="form-group">
                        <label htmlFor="product">Select a product <ErrorMessage name="product" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faQuestionCircle} />
                            </div>
                          </div>
                          <Field
                            component="select"
                            name="product"
                            id="product"
                            className="form-control"
                            onChange={(e) => {
                              setFieldValue('product', e.target.value)
                              setProductTypeSelected(e.target.value)
                            }}
                            value={productTypeSelected !== undefined ? productTypeSelected : ""}
                          >
                            <option value=""></option>
                            {product_type_options}
                          </Field>
                        </div>
                      </div>
                    </div>

                    {
                      productTypeSelected === 'Activity' && 
                      <div className={colWidth}>
                        <div className="form-group">
                          <label htmlFor="product_name">Select an activity <ErrorMessage name="product_name" component="span" className="error-msg"/></label> 
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faListUl} />
                              </div>
                            </div>
                            <Field
                              component="select"
                              name="product_name"
                              id="product_name"
                              className="form-control"
                              onChange={(e) => {
                                setFieldValue('product_name', e.target.value)
                                setProductSelected(e.target.value)
                              }}
                              value={productSelected ? productSelected : ''}
                            >
                              <option value=""></option>
                              {data.allMarkdownRemark.edges.map(({node}) => {
                                if(node.frontmatter.type === productTypeSelected)
                                  return (<option  key={uuid.v4()} value={node.frontmatter.title}>{node.frontmatter.title}</option>)
                                else
                                  return false
                              })}
                            </Field>
                          </div>
                        </div>
                      </div>
                    }

                    {
                      ['Luxury room'].includes(productTypeSelected) && 
                      <div className={colWidth}>
                        <div className="form-group">
                          <label htmlFor="bed_type">Bed type<ErrorMessage name="bed_type" component="span" className="error-msg"/></label> 
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faBed} />
                              </div>
                            </div>
                            <Field component="select" name="bed_type" id="bed_type" className="form-control">
                              <option value=""></option>
                              <option value="1 single bed">1 single bed</option>
                              <option value="1 extra-large double bed">1 extra-large double bed</option>
                              <option value="2 single beds">2 single beds</option>
                            </Field>
                          </div>
                        </div>
                      </div>
                    }

                    {
                      productTypeSelected === 'Desert camp' && 
                      <div className={colWidth}>
                        <div className="form-group">
                          <label htmlFor="camp_type">Tent type <ErrorMessage name="camp_type" component="span" className="error-msg"/></label> 
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <FontAwesomeIcon icon={faCampground} />
                              </div>
                            </div>
                            <Field component="select" name="camp_type" id="camp_type" className="form-control">
                              <option value=""></option>
                              <option value="Single">Single</option>
                              <option value="Double">Double</option>
                              <option value="Triple">Triple</option>
                              <option value="Quadruple">Quadruple</option>
                            </Field>
                          </div>
                        </div>
                      </div>
                    }
                    
                    <div className={colWidth}>
                      <div className="form-group">
                        <label htmlFor="guests">Number of persons <ErrorMessage name="guests" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faUsers} />
                            </div>
                          </div>
                          <Field type="number" name="guests" id="guests" className="form-control"/>
                        </div>
                      </div>
                    </div>

                    <div className={colWidth}>
                      <div className="form-group">
                        <label htmlFor="arrival_date">Arrival Date <ErrorMessage name="arrival_date" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                          </div>
                          <Field type="date" name="arrival_date" id="arrival_date" className="form-control" min={min_date} />
                        </div>
                      </div>
                    </div>

                    <div className={colWidth}>
                      <div className="form-group">
                        <label htmlFor="departure_date">Departure Date <ErrorMessage name="departure_date" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCalendarAlt} />
                            </div>
                          </div>
                          <Field type="date" name="departure_date" id="departure_date" className="form-control" min={min_date} />
                        </div>
                        
                      </div>
                    </div>
                  
                    <div className={colWidth}>
                      <div className="form-group">
                        <label htmlFor="full_name">Full Name <ErrorMessage name="full_name" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faUserAlt} />
                            </div>
                          </div>
                          <Field type="text" name="full_name" id="full_name" className="form-control"/>
                        </div>
                      </div>
                    </div>

                    <div className={colWidth}>
                      <div className="form-group">
                        <label htmlFor="phone">Phone <ErrorMessage name="phone" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faPhone} />
                            </div>
                          </div>
                          <Field type="text" name="phone" id="phone" className="form-control"/>
                        </div>
                      </div>
                    </div>

                    <div className={colWidth}>
                      <div className="form-group">
                        <label htmlFor="email" id="">E-mail address <ErrorMessage name="email" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                          </div>
                          <Field type="email" name="email" id="email" className="form-control"/>
                        </div>
                      </div>
                    </div>
                  
                  </div>

                  <div className="row">
                    <div className={colWidth === 'col-md-4' ? 'col-md-8' : 'col-md-12'}>
                      <div className="form-group">
                        <label htmlFor="message">Message <ErrorMessage name="message" component="span" className="error-msg"/></label> 
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <FontAwesomeIcon icon={faCommentAlt} />
                            </div>
                          </div>
                          <Field component="textarea" name="message" id="message" className="form-control"/>
                        </div>
                        
                      </div>
                    </div>

                    <div className={colWidth}>
                      <div className="form-group terms_and_cond">
                        <label><input type="checkbox" name="terms_conditions"id="terms_conditions" required/> Agree with <Link to="/terms-and-conditions">terms and condition</Link></label>
                        <ErrorMessage name="terms_conditions" component="div" className="error-msg" />
                      </div>
                      <div>
                        <button type="submit" disabled={isSubmitting} className="submit-btn">
                          Book Now <FontAwesomeIcon icon={faCalendarCheck} />
                        </button>
                      </div>
                    </div>

                  </div>
                
                </Form>
              )}
            </Formik>
          </div>
        )
      }}

    />
  )

}

export default BookingForm
import React from 'react'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BgImage = ({ className, image }) => (
  <BackgroundImage
    Tag="div"
    className={className}
    fluid={image.childImageSharp.fluid}
    backgroundColor={`#040e18`}
  />
)

const StyledBgImage = styled(BgImage)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBgImage